import { Cart } from "../models/cart";
import { Product } from "../models/product";

export class DataManager {
    private _basePath: string;
    private _nonce: string = "";
    private _cartToken: string = "";
    constructor(basePath: string) {
        this._basePath = basePath
    }


    getUrlCheckout() {
        return this._basePath + "/pagamento/"
    }

    listProducts() {
        return new Promise<Product[]>((resolve, reject) => {
            fetch(this._basePath + "/wp-json/wc/store/v1/products", {
                credentials: 'include'
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }
                else {
                    const json = await response.json();
                    resolve(json)
                }
            }).catch((err) => {
                console.log(err);
                reject(err)
            })
        })

    }

    getCart() {
        return new Promise<Cart>((resolve, reject) => {
            fetch(this._basePath + "/wp-json/wc/store/v1/cart", {
                credentials: 'include'
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }
                else {
                    this._nonce = response.headers.get("Nonce") ?? "";
                    this._cartToken = response.headers.get("Cart-Token") ?? "";
                    const json = await response.json();
                    resolve(json)
                }
            }).catch((err) => {
                console.log(err);
                reject(err)
            })
        })

    }

    addInCart(product: Product, quantity: number) {
        return new Promise<Cart>((resolve, reject) => {
            fetch(this._basePath + "/wp-json/wc/store/v1/cart/add-item", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    "id": product.id,
                    "quantity": quantity
                }),
                headers: {
                    "Nonce": this._nonce ?? "",
                    "Content-Type": "application/json"
                }
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }
                else {
                    const json = await response.json();
                    resolve(json)
                }
            }).catch((err) => {
                console.log(err);
                reject(err)
            })
        })
    }
    updateItemInCart(product: Product, quantity: number) {
        return new Promise<Cart>((resolve, reject) => {
            fetch(this._basePath + "/wp-json/wc/store/v1/cart/update-item", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    "key": product.key,
                    "quantity": quantity
                }),
                headers: {
                    "Nonce": this._nonce ?? "",
                    "Content-Type": "application/json"
                }
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }
                else {
                    const json = await response.json();
                    resolve(json)
                }
            }).catch((err) => {
                console.log(err);
                reject(err)
            })
        })
    }

    removeItemInCart(product: Product) {
        return new Promise<Cart>((resolve, reject) => {
            fetch(this._basePath + "/wp-json/wc/store/v1/cart/remove-item", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                    "key": product.key
                }),
                headers: {
                    "Nonce": this._nonce ?? "",
                    "Content-Type": "application/json"
                }
            }).then(async (response) => {
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }
                else {
                    const json = await response.json();
                    resolve(json)
                }
            }).catch((err) => {
                console.log(err);
                reject(err)
            })
        })
    }

}