import { useContext } from "react";
import NavBarComp from "../../components/navbar/navbar";
import { CartContext, DataManagerContext } from "../../App";
import './checkout.scss'
export interface CheckoutProps {

}

function Checkout(props: CheckoutProps) {
    const cartContext = useContext(CartContext);
    const dataManager = useContext(DataManagerContext);

    return (
        <div className="checkout">
            <NavBarComp active="checkout" nCart={cartContext.cart ? cartContext.cart.items_count : 0}></NavBarComp>
            <iframe className="iframeContainer" src={dataManager.getUrlCheckout()}></iframe>
        </div>
    );
}

export default Checkout