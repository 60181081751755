// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper'; // Importa il tipo

// import Swiper and modules styles
import 'swiper/scss';
import './home.scss'
import { useContext, useEffect, useRef, useState } from 'react';
import NavBarComp from '../../components/navbar/navbar';
import { CartContext, DataManagerContext } from '../../App';
import { Product } from '../../models/product';

export interface HomeProps {

}

function Home(props: HomeProps) {

    const dataManager = useContext(DataManagerContext)
    const cartContext = useContext(CartContext);
    const [products, setProducts] = useState<Product[]>([]);
    const [currentItem, setCurrentItem] = useState<Product>()
    useEffect(() => {
        var promises = [];
        promises.push(dataManager.listProducts());
        promises.push(dataManager.getCart());
        Promise.all(promises)
            .then((responses: any) => {
                setProducts(responses[0]);
                cartContext.setCart(responses[1]);
            })
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        setCurrentItem(products[0])
    }, [products])


    // Riferimento all'istanza di Swiper
    const swiperRef = useRef<SwiperType | null>(null);


    const handleNext = () => {
        if (swiperRef.current) swiperRef.current.slideNext();
    };

    const handlePrev = () => {
        if (swiperRef.current) swiperRef.current.slidePrev();
    };
    // Callback per il cambio di slide
    const handleSlideChange = (swiper: SwiperType) => {
        if (swiperRef.current)
            setCurrentItem(products[swiperRef.current.realIndex])
    };

    const getProductImage = (product: Product) => {
        if (product.images.length > 0) {
            return <img src={product.images[0].src} alt={product.images[0].alt} />
        }
        else return <img src="./images/placeholder.png" alt="placeholder prodotto" />
    }

    const getProductPrice = (product: Product) => {
        const price = (parseInt(product.prices.price) / 100).toFixed(2).replace('.', ',');
        return `${product.prices.currency_symbol} ${price}`
    }

    const addInCart = (product:Product)=>{
        dataManager.addInCart(product,1).then((cart)=>{
            cartContext.setCart(cart);
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    return (
        <div className='home'>

            <NavBarComp active='home' nCart={cartContext.cart ? cartContext.cart.items_count : 0}></NavBarComp>

            <div className="container mt-2">
                <div className="row justify-content-md-center">
                    <div className="col col-sm-12 col-md-12 col-lg-10">
                        <p className="intestazione text-center m-0">i nostri</p>
                        <p className="subIntestazione text-center m-0">PANETTONI ARTIGIANALI</p>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col col-sm-12 col-md-12 col-lg-10">
                        <div className="swiper-container">
                            <Swiper
                                onSwiper={(swiper: any) => (swiperRef.current = swiper)}
                                onSlideChange={(swiper: any) => handleSlideChange(swiper)}
                                slidesPerView={5}
                                centeredSlides={true}
                                spaceBetween={0}
                                loop={true} // se vuoi un loop continuo
                            >
                                {products.map((product, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="slide-content">
                                                {getProductImage(product)}
                                                <p className="label">{product.name}</p>
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}

                            </Swiper>


                        </div>

                    </div>
                </div>

                <div className="row justify-content-md-center mb-5">
                    <div className="col col-sm-12 col-md-12 col-lg-10">
                        {currentItem && <div id="product-detail">
                            <div className="row d-flex align-items-center">
                                <div className="col col-2 ">
                                    <button className="btn" onClick={() => { handlePrev() }}>
                                        <i className="bi bi-caret-left-fill arrowmove"></i>
                                    </button>
                                </div>
                                <div className="col col-8 ">
                                    {getProductImage(currentItem)}
                                </div>
                                <div className="col col-2 ">
                                    <button className="btn" onClick={() => { handleNext() }}>
                                        <i className="bi bi-caret-right-fill arrowmove"></i>
                                    </button>
                                </div>
                            </div>
                            <p className="label">{currentItem.name}</p>
                            <div className="px-3">
                                <p className="description" dangerouslySetInnerHTML={{ __html: currentItem.short_description }}></p>
                                <div className="row d-flex align-items-center">
                                    <div className="col col-6 ">
                                        <p className="price m-0">{getProductPrice(currentItem)} <span className="iva-label">iva inclusa</span></p>
                                    </div>
                                    <div className="col col-6 d-flex justify-content-end">
                                        <button className="btn btn-carrello" onClick={()=>addInCart(currentItem)}>
                                            AGGIUNGI AL CARRELLO
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>}
                    </div>

                </div>
            </div>

        </div>

    );
}

export default Home