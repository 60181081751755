
import { useContext } from 'react'
import './cart-comp.scss'
import { CartContext, DataManagerContext } from '../../App'
import { Product } from '../../models/product';
import { Cart } from '../../models/cart';
import { useNavigate } from 'react-router-dom';
export interface CartCompProps {

}

function CartComp(props: CartCompProps) {
    const dataManager = useContext(DataManagerContext);
    const cartContext = useContext(CartContext);
    const navigate = useNavigate();

    const getProductImage = (product: Product) => {
        if (product.images.length > 0) {
            return <img width={"100%"} src={product.images[0].src} alt={product.images[0].alt} />
        }
        else return <img width={"100%"} src="./images/placeholder.png" alt="placeholder prodotto" />
    }

    const getProductPrice = (product: Product) => {
        const price = (parseInt(product.prices.price) / 100).toFixed(2).replace('.', ',');
        return `${product.prices.currency_symbol} ${price}`
    }

    const getProductTotalPrice = (product: Product) => {
        if (product.totals) {
            const price = (parseInt(product.totals.line_subtotal) / 100).toFixed(2).replace('.', ',');
            return `${product.totals.currency_symbol} ${price}`
        } else return "";

    }

    const getSubTotalPrice = (cart?: Cart) => {
        if (cart && cart.totals) {
            const price = (parseInt(cart.totals.total_price) / 100).toFixed(2).replace('.', ',');
            return `${cart.totals.currency_symbol} ${price}`
        } else return "";

    }

    const increment = (product: Product) => {
        if (product.quantity) {
            dataManager.updateItemInCart(product, (product.quantity + 1)).then((cart) => {
                cartContext.setCart(cart);
            })
                .catch((err) => console.log(err))
        }
    }

    const decrement = (product: Product) => {
        if (product.quantity && product.quantity > 1) {
            dataManager.updateItemInCart(product, (product.quantity - 1)).then((cart) => {
                cartContext.setCart(cart);
            })
                .catch((err) => console.log(err))
        }
    }

    const removeProductInCart = (product: Product) => {
        dataManager.removeItemInCart(product).then((cart) => {
            cartContext.setCart(cart);
        })
            .catch((err) => console.log(err))
    }

    const goCheckout = () => {
        navigate("/checkout")
    }

    return (
        <div className="cartComp">
            <div className='itemsContainer'>
                {cartContext.cart?.items.map((product) => {
                    return (<div  key={product.key}>
                        <div className='row mb-1'>
                            <div className='col col-3'>
                                {getProductImage(product)}
                            </div>
                            <div className='col col-9'>
                                <p>{product.quantity} x {product.name}</p>
                                <p>{getProductTotalPrice(product)}</p>
                                <div className="input-group w-50 mb-2">
                                    <button className="btn btn-outline-secondary" type="button" disabled={(product.quantity ?? 0) < 2} onClick={() => { decrement(product) }}>-</button>
                                    <input type="text" id="numericInput" className="form-control text-center" value={product.quantity} readOnly />
                                    <button className="btn btn-outline-secondary" type="button" onClick={() => { increment(product) }}>+</button>
                                </div>
                                <button className='btn btn-link p-0 text-dark' onClick={() => { removeProductInCart(product) }}>Rimuovi articolo</button>
                            </div>
                        </div>
                        <hr></hr>
                    </div>
                    )
                })}
            </div>

            <div className='footer mb-3'>
                <p>Subtotale <b className='float-end'>{getSubTotalPrice(cartContext.cart)}</b></p>
                <div>
                    <button className='btn btn-dark w-100'
                        disabled={!cartContext.cart || cartContext.cart.items_count < 1}
                        onClick={() => goCheckout()}>Vai al pagamento</button>
                </div>
            </div>
        </div>

    )
}

export default CartComp