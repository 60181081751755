
import CartComp from '../cart/cart-comp';
import './navbar.scss'
export interface NavBarCompProps {
    active: string
    nCart: number;
}

function NavBarComp(props: NavBarCompProps) {
    return (
        <div className="navbarComp">
            <nav className="navbar p-0">
                <div className="container-fluid">
                    {/*<!--ITEMS DELL'HEADER -->*/}
                    <button className="navbar-toggler cartbutton" type="button" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                        <i className="bi bi-list"></i>
                    </button>
                    <a className="navbar-brand mx-auto" href="#">
                        <img width="80" height="80" src="./images/logo.png" alt="Logo" />
                    </a>
                    <button className="navbar-toggler cartbutton" type="button"  data-bs-toggle="offcanvas"
                        data-bs-target="#panelCart" aria-controls="panelCart">
                        <i className="bi bi-cart "></i>
                        <span className="badge badgeCart">{props.nCart}</span>
                    </button>

                    {/* <!--PANNELO LATERALE DI NAVIGAZIONE-->*/}
                    <div className="offcanvas offcanvas-end" id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">In-Out</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                <li className="nav-item">
                                    <a className={`nav-link ${props.active == 'home' ? 'active' : ''}`} aria-current="page" href="../">
                                        <i className="bi bi-house me-2"></i>
                                        Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${props.active == 'contact' ? 'active' : ''}`} href="../contact">
                                        <i className="bi bi-info-square me-2"></i>
                                        Contatti</a>
                                </li>
                                <div className="footer">
                                    <p>&copy; Copyright 2024 - InOut</p>
                                </div>
                            </ul>
                        </div>
                    </div>


                    {/* <!--PANNELO LATERALE CARRELLO-->*/}
                    <div className="offcanvas offcanvas-end" id="panelCart"
                        aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Il tuo carrello ({props.nCart} articoli)</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body overflow-hidden">
                           <CartComp></CartComp>
                        </div>
                    </div>


                </div>
            </nav>
        </div>

    )
}

export default NavBarComp