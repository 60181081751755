import { useContext } from "react";
import NavBarComp from "../../components/navbar/navbar";
import { CartContext } from "../../App";

export interface ContactProps {

}

function Contact(props: ContactProps) {
    const cartContext = useContext(CartContext);

    return (
        <div className="contact">
            <NavBarComp active="contact" nCart={cartContext.cart ? cartContext.cart.items_count : 0}></NavBarComp>
        </div>
    );
}

export default Contact