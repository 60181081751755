import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home/home';
import Contact from './pages/contact/contact';
import { createContext, useEffect, useState } from 'react';
import { DataManager } from './services/dataManager';
import { Cart } from './models/cart';
import Checkout from './pages/checkout/checkout';

export const DataManagerContext = createContext(new DataManager(process.env.REACT_APP_BASEPATH ?? ''));
export const CartContext = createContext<{
  cart: Cart | undefined,
  setCart: (c: Cart) => void
}>({ cart: undefined, setCart: () => { } });
const dataManager=new DataManager(process.env.REACT_APP_BASEPATH ?? '');

function App() {

  const [cart, setCart] = useState<Cart>()
  return (
    <DataManagerContext.Provider value={dataManager}>
      <CartContext.Provider value={{ cart: cart, setCart: (c) => setCart(c) }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/contact" Component={Contact} />
            <Route path="/checkout" Component={Checkout} />
            <Route path="*" Component={Home} />
          </Routes>
        </BrowserRouter>
      </CartContext.Provider>

    </DataManagerContext.Provider>

  );
}

export default App;
